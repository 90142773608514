<script setup lang="ts">
import useUserStore from "~/stores/user";
import EventsAPI from "~/services/events";
import HeaderAPI from "~/services/header";
import { THEME_COLOR } from "~/constants";
import FollowAPI from "~/services/follow";
import useSSOLogin from "~/hooks/use-sso-login";
import getErrorLocalePathMessage from "~/utils/locale";

const route = useRoute();
const router = useRouter();
const toaster = useToast();
const { t, te } = useI18n();
const color = useColorMode();
const userStore = useUserStore();
const appConfig = useRuntimeConfig().public;

const componentKey = ref(0);
const isLoggedIn = computed(() => Boolean(userStore.user));
const searchValue = computed(() => route.query.q as string);
const withSearch = computed(() => !(route.path.startsWith("/search") || route.path.startsWith("/archive")));
const defaultLocale = appConfig.APP_CUSTOMER.default_language as ILocale;
const availableLocales = appConfig.APP_CUSTOMER.available_languages_list as ILocale[];
const customerName = appConfig.APP_CUSTOMER.name;
const hasThemeSwitcher = appConfig.APP_FLAGS.hasWhiteTheme;
const hasNotifications = appConfig.APP_FLAGS.hasNotifications;
const isLeagueView = appConfig.APP_CUSTOMER.enable_leagues_view;
const mostCommonSportTypesList = appConfig.APP_CUSTOMER.most_common_sport_types_list;
const footerText = appConfig.APP_CUSTOMER.footer_text;
const CUSTOMER_LOGO_URL = {
  white: appConfig.APP_CUSTOMER.appearance.logo_white_url,
  black: appConfig.APP_CUSTOMER.appearance.logo_black_url,
  default: appConfig.APP_CUSTOMER.appearance.logo_url,
};

const onSocialLoginSuccess = () => {
  componentKey.value++;
};

const { executeSocialLogin, socialLoginLoading } = useSSOLogin({ onSuccess: onSocialLoginSuccess });
const loginProvider = computed(() => {
  if (appConfig.APP_FLAGS.hasIfaAuth)
    return "ifa";

  return undefined;
});
const logo_url = computed(() => {
  return (color?.value === THEME_COLOR.DARK ? CUSTOMER_LOGO_URL.black : CUSTOMER_LOGO_URL.white) || CUSTOMER_LOGO_URL.default || "";
});
const footer_links = computed(() => appConfig.APP_CUSTOMER.footer_links as FooterLink[]);
const footer_social_links = {
  facebookUrl: appConfig.APP_CUSTOMER.social.url_facebook,
  xUrl: appConfig.APP_CUSTOMER.social.url_x,
  instagramUrl: appConfig.APP_CUSTOMER.social.url_instagram,
  mailUrl: appConfig.APP_CUSTOMER.social.url_email,
  googlePlayUrl: appConfig.APP_CUSTOMER.social.url_google_play_store,
  appStoreUrl: appConfig.APP_CUSTOMER.social.url_app_store,
  youtubeUrl: appConfig.APP_CUSTOMER.social.url_youtube,
  tikTokUrl: appConfig.APP_CUSTOMER.social.url_tik_tok,
};

const { data: headerLinks } = useAsyncData("header-links", HeaderAPI.load);

const onSearch = (searchValue: string) =>
  router.push({ path: "/search/", query: { q: searchValue } });
const onLogout = () => {
  userStore.logout();

  if (route.meta.middleware === "auth")
    router.push({ path: "/" });
};

onMounted(() => {
  if (isLoggedIn.value) {
    Promise.all([
      EventsAPI.selected.load(),
      isLeagueView ? FollowAPI.load({ types: "team" }) : FollowAPI.load({ types: "identity_item" }),
    ]).catch((error: Error) => {
      const { message } = getErrorLocalePathMessage(error.message, { t, te });
      toaster.error(message);
    });
  }
});
</script>

<template>
  <div class="homeLayout">
    <BaseHeader
      fixed
      :with-search="withSearch"
      with-mobile-menu
      :with-auth="!isLoggedIn"
      :with-user="userStore.user"
      :with-notifications="hasNotifications"
      :with-theme-switcher="hasThemeSwitcher"
      :links="headerLinks || []"
      :disabled-auth="socialLoginLoading"
      :logo-image-url="logo_url"
      :provider="loginProvider"
      :search-value="searchValue"
      :default-locale="defaultLocale"
      :available-locales="availableLocales"
      :most-common-sport-types-list="mostCommonSportTypesList as TSportType[]"
      @search="onSearch" @logout="onLogout()"
      @login-provider="executeSocialLogin"
    />

    <div class="mt-20 md:mt-[6.5rem]" />

    <main :key="componentKey" class="homeLayout__content">
      <slot />
    </main>

    <BaseFooter
      :logo-image-url="logo_url"
      :links="footer_links"
      :social-links="footer_social_links"
      :customer-name="customerName"
      :is-theme-switcher-shown="hasThemeSwitcher"
      :footer-text="footerText"
    />
  </div>
</template>

<style lang="scss">
.homeLayout {
  background-color: var(--base-background-color);

  .homeLayout__content {
    @apply h-full;
    // TODO: implement via grid
    min-height: calc(100vh - 305px);
  }
}
</style>
